import React, { useEffect, useState } from 'react';
import styles from './AllCampaigns.module.scss';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import { Button, Image, Radio } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignsSummary } from '../../../Redux/Features/getCampaignsSummarySlice';
import { getQueryParameters } from '../../../utils/helper';

const AllCampaigns = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { products } = useSelector((state) => state?.getProducts);
  const { campaigns } = useSelector((state) => state?.getCampaignsSummary);
  const [visibleCampaigns, setVisibleCampaigns] = useState(campaigns);

  useEffect(() => {
    dispatch(getCampaignsSummary());
  }, [location]);

  useEffect(() => {
    setVisibleCampaigns(campaigns);
  }, [campaigns]);

  const getProductName = (product_id) => {
    const product = products?.find((item) => item?._id === product_id);
    return product?.product_name;
  };

  const styleHandler = (campaign) => {
    if (campaign.is_active && campaign?.status === 'Active') {
      return styles.active;
    }
    if (!campaign.is_active && campaign?.status === 'Blocked') {
      return styles.canceled;
    }
    if (campaign?.status === 'Login Failed') {
      return styles.canceled;
    }
    if (campaign?.status === 'Invalid Business Page') {
      return styles.canceled;
    }
    if (!campaign.is_active) {
      return styles.paused;
    }
  };

  let isCampaignPaused = (campaign) => {
    return ['Paused', 'Disabled', 'Draft'].includes(campaign?.status);
  }

  const showCampaignsOptions = ["All", "Active", "Paused"];
  const [showCampaignFilter, setShowCampaignFilter] = useState('All');
  let onChangeShowCampaignFilter = (e) => {
    setShowCampaignFilter(e.target.value);
    const selection = e.target.value;



    if (selection === 'All') {
      setVisibleCampaigns(campaigns);
    } else if (selection === 'Active') {
      setVisibleCampaigns(campaigns?.filter((campaign) => !isCampaignPaused(campaign)));
    } else if (selection === 'Paused') {
      setVisibleCampaigns(campaigns?.filter((campaign) => isCampaignPaused(campaign)));
    }

  };

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h2>Campaigns</h2>
            </div>
            <Radio.Group options={showCampaignsOptions} onChange={onChangeShowCampaignFilter} value={showCampaignFilter} optionType="button" />
            <Button size="small" onClick={() => navigate('/create-campaign')}>
              Create new campaign <Image src={'/Assets/plus_icon.svg'} alt={'Plus icon'} preview={false} />
            </Button>
          </div>
        }
      />
      <div className={styles.main_campaigns_container}>
        <div className={styles.title_container}>
          <h6>Campaigns</h6>
        </div>
        {visibleCampaigns?.map((item, i) => (
          <div
            className={styles.campaign}
            key={i}
            onClick={() =>
              navigate(
                item?.status === 'Un-Paid' ? `/create-campaign?id=${item?._id}` : `/campaign/${item?._id}`
              )
            }
          >
            <div className={styles.title}>
              <h5>{item?.title}</h5>
            </div>
            <div className={styles.status}>
              <div className={styleHandler(item)}>
                <div className={styles.dot}></div>
                <span>{item?.status}</span>
              </div>
            </div>
            <div className={styles.card_info}>
              {item?.created_by === 'INTERNAL' ? (
                <>
                  <p>External</p>
                  <span>External Payment</span>
                </>
              ) : (
                <>
                  <p></p>
                  <span></span>
                </>
              )}
            </div>
            <div className={styles.subscription}>
              <p>
                {getProductName(item.product_id)}
              </p>
            </div>
            <div className={styles.card_info}>
              <p>{item.business_page.name}</p>
              <span>{item?.linkedin_account?.linkedin_name}</span>
            </div>
          </div>
        ))}
      </div>
    </AuthLayout>
  );
};

export default AllCampaigns;
